import React from 'react'
import PropTypes from 'prop-types';
import { Map, TileLayer } from 'react-leaflet'
import "./MapWidget.css";
import L from 'leaflet';

class MapWidget extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
          lat: 47.607851, //47.607851, 
          lng: -122.338638, //-122.338638
          zoom: 5,
          markers:[],
          currentZoomLevel: 5,
          isLoaded: false,
          layerGroup: null
        }
      }


    componentDidUpdate() {
     console.log("MpaWidget-Updated: "+this.state.isLoaded);
        if(!this.state.isLoaded && this.props.locations!==undefined){
          console.log("LOADING");
          this.mapLocationData();
            this.setState({isLoaded:true});
        }else{
          console.log(this.state);
    }
    }

    setView(coordinates, zoom, minzoom){
        this.leafletMap.leafletElement.setView(
            coordinates,
            zoom===null? (this.state.currentZoomLevel<minzoom? minzoom : this.state.currentZoomLevel) : zoom,
            {
                "animate": true,
                "pan": {
                  "duration": 1
                }
              });
    }

    /// This function puts the given markers on the map
    mapLocationData(){
      console.log("Resetting Map");
      console.log(this.props);

      var markers=[];
      const clickFunction = this.props.clickFunction

      if(this.state.layerGroup!== null){
        console.log("CLEAR LAYERS");
        this.state.layerGroup.clearLayers();
      }else{
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {attribution: '&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'}).addTo(this.leafletMap.leafletElement);
      }

      //layergroup
      var layer = L.layerGroup().addTo(this.leafletMap.leafletElement);

      //generate marker list
      this.props.locations.forEach(function (item, i){
        L.marker([item.lat, item.long]).on('click', () => clickFunction(item)).addTo(layer);
      });
        
      //this.state.markers = markers;
      this.setState({layerGroup: layer})
      console.log("MAP RENDERED")
    }

    render() {
        //this.props.clickFunction(this.props.locations[0]);
        const position = [(this.props.lat===undefined)? 51.505: this.props.lat,(this.props.lat===undefined)? -0.09: this.props.lng];
        if (typeof window !== 'undefined') {
         // console.log(this.props.locations);
          return (
              <Map ref={m => { this.leafletMap = m; }} 
                className={"MapWidget-Map"} 
                center={position} 
                zoom={this.state.zoom} 
                maxZoom={19} >
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {/*<MyMarkersList markers={markers} clickFunction={this.props.clickFunction} /> */}
              </Map>
          )
        }
        return null;
    }
}

MapWidget.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  zoom: PropTypes.number,
  locations: PropTypes.array,
  activeLocation: PropTypes.object,
  clickFunction: PropTypes.func
};

export default MapWidget;


// */