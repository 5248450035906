import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import MapWidget from "../components/MapWidget";
import MapWidgetHeader from "../components/MapWidgetHeader";

class MapPageObject extends React.Component {

    constructor() {
        super();
        this.state = {
          activeLocation:null,
          locations:[],
          activeLocationIndex:0,
          zoom:14,
          isLoaded: false
        };

        this.handleMapClick.bind(this);
        this.handleBackwardClick.bind(this);
        this.handleForwardClick.bind(this);
      }

    handleBackwardClick = (e) =>{
        try{
            //if in bounds of list size
            console.log("clicked backward from location: "+this.state.activeLocationIndex)
            if(this.state.activeLocationIndex<=0){
                this.state.activeLocationIndex = this.state.locations.length;
            }
            //click it.
            this.handleMapClick(this.state.locations[this.state.activeLocationIndex-1]);
        }catch(err){
            console.log(err);
            alert(err);
        }
    }

    handleForwardClick = (e) =>{
        try{
            console.log("clicked forward from location: "+this.state.activeLocationIndex)
            console.log(this.state.locations.length);
            //if in bounds of list size
            if(this.state.activeLocationIndex >= this.state.locations.length-1){
                this.state.activeLocationIndex = -1;
            }
            //click it
            this.handleMapClick(this.state.locations[this.state.activeLocationIndex+1]);
        }catch(err){
            console.log(err);
            alert(err);
        }
    }

    handleMapClick = (e) => {
        try{
            //set active location
            console.log(e);
            this.setState({activeLocation:e, activeLocationIndex:e.index});
            
            if(this.mapWidget){
                this.mapWidget.setView([e.lat,e.long],null,16);
            }
        }catch(err){
            console.log(err);
            alert(err);
        }
    }

    handleReviewClick = (e) => {
        try{
            //this.mapWidget.addMarker("test");
            this.mapWidget.mapLocationData(this.state.locations);
        }catch(err){
            console.log(err);
            alert(err);
        }
    }
    
    prepMapForFirstRun(){
        try{
            console.log('prepMapForFirstRun:start');
            this.state.locations = this.props.locations;
            this.state.activeLocationIndex = 0;
            this.state.zoom=this.props.zoom
            
           // this.setState({locations:this.props.locations, activeLocationIndex:0, zoom: this.props.zoom})
            
            //number locations
            var index = 0;
            this.state.locations.forEach(item=>{item.index=index++;})
            
            this.mapWidget.mapLocationData(this.state.locations);
            this.handleMapClick(this.state.locations[0]);
            this.setState({isLoaded:true});

            console.log('prepMapForFirstRun:end');
        }catch(err){
            alert(err);
        }
    }
    
    componentDidMount(){
        console.log("MapObject - componentDidMount");
        try{
            if(!this.state.isLoaded){
                this.prepMapForFirstRun();
              }else{
                console.log(this.state);
          }
        }catch(err){
            alert(err);
        }

        setTimeout(function() { //Start the timer
            try{
              this.prepMapForFirstRun();
            }catch(err){
                alert(err);
            }
        }.bind(this), 1000)
    }

    render() {
        if(this.state.activeLocation===null){
            console.log("NO ACTIVE LOCATIONS");
            //this.prepMapForFirstRun();
        }
        return (
            <div className="MapObject container relative">
            <div className="MapWidgetHeader--Bar">
            <MapWidgetHeader clickPrev={this.handleBackwardClick} clickNext={this.handleForwardClick} clickReview={this.handleReviewClick} location={this.state.activeLocation} />
            </div>

            <div className={"MapWidget MapPage--Section"}> 
              {typeof window !== 'undefined' && 
                <MapWidget ref={m => { this.mapWidget = m; }}  clickFunction={this.handleMapClick} zoom={this.state.zoom} activeLocation={this.state.activeLocation} locations={this.state.locations} lat={this.props.lat} lng={this.props.lng} />
              }
            </div>
            </div>
        )
        
    }
}

MapPageObject.propTypes = {
    lat: PropTypes.number,
    lng: PropTypes.number,
    zoom: PropTypes.number,
    locations: PropTypes.array
};

export default MapPageObject;


// */