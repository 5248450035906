import React from 'react'
import PropTypes from 'prop-types';
import "./MapWidgetHeader.css";
import BackgroundImage from 'gatsby-background-image'


class MapWidgetHeader extends React.Component {

  constructor() {
    super();
  }

  handlePhoneClick(number){
    try{
      console.log("CALLING: "+number);
      window.open('tel:'+number);
    }catch(err){
      alert(err);
    }
  }

  handleDirectionsClick(title,address){
    try{
      const encodedURL = "https://www.google.com/maps/search/?api=1&query="+encodeURI(title+address);
      window.open(encodedURL);
    }catch(err){
      alert(err);
    }
  }

  handleWebsiteClick(website){
    try{
      console.log("NAVIGATING: "+website);
      window.open('http://'+website);
    }catch(err){
      alert(err);
    }
  }

  isEnabled(value){
    if(value==="" || value===null)
      return false;
    return true;
  }

  render() {
    var categories=[];
    if(this.props && this.props.location && this.props.location.categories){
      console.log(this.props.location.categories[0].category.toLowerCase())
      categories = this.props.location.categories.filter(post => (post.category.toLowerCase()!=='seattle' && post.category.toLowerCase()!=='bend takeout' && post.category.toLowerCase()!=='bend'));
    }

    var isCOVID=false;
    if(this.props && this.props.location && this.props.location.iscovidmap==true){
      isCOVID=true;
    }

    var isLanding=false;
    if(this.props && this.props.location && this.props.location.islanding==true){
      console.log("SHOW LANDING PAGE");
      isLanding=true;
    }

    return (
      <div ref={m=>{this.wrapperRef = m}} className="MapWidget--Header"> 

        {(isLanding) &&
          <div>
            WELCOME TO THE MAP
            </div>
        }

         {(this.props && this.props.location) && 
        <div className={`MapWidget--Header-Text`}>
            <div className={"MapWidget--Header-Title"}>
               {this.props.location.title==="000 A Welcome Message"?"The Bend Takeout & Delivery Map": this.props.location.title}
            </div>
            
            {this.props.location && this.props.location.featuredImage && (
                <div className={"MapWidget--Header-Image "} > 
                  <BackgroundImage
                    Tag="section"
                    className={"BackgroundImage absolute"}
                    fluid={this.props.location.featuredImage.childImageSharp.fluid}
                    backgroundColor={`transparent`}
                  />
                </div>
                )}
                 <div className={`MapWidget--Header-Categories`}>{categories.map(cat => cat.category).join(', ')}</div>

            <div>
    
            {(!isCOVID) && 
              <div className={'MapWidget--Header-Excerpt'}>
                <div className={'MapWidget--Header-Excerpt-LongForm'}>{(this.props.location.mapStory)? this.props.location.mapStory : this.props.location.excerpt }&nbsp;&nbsp;&nbsp;<a href={"../.."+this.props.location.slug+""}>Read Full Story</a></div>
                <div className={'MapWidget--Header-Excerpt-ShortForm'}>{(this.props.location.excerpt) && this.props.location.excerpt }&nbsp;&nbsp;&nbsp;<a href={"../.."+this.props.location.slug+""}>Read Full Story</a></div>
              </div>
            }
            {(isCOVID) && 
              <div className={'MapWidget--Header-HTML'}>
                <div dangerouslySetInnerHTML={{ __html: this.props.location.html }}></div>
              </div>
            }

            </div>
            <div className={'MapWidget--Header-Buttons'}>
              {console.log(this.props.location)}
              <button className="highlight-map-button" onClick={this.props.clickPrev}><div className="button-icon"><i className="fas fa-arrow-left"></i></div><br />Previous</button>
              <button onClick={()=>{this.handlePhoneClick(this.props.location.phone)}} disabled={!this.isEnabled(this.props.location.phone)} ><div className="button-icon"><i className="fas fa-phone"></i></div><br />Call</button>
              <button className="hide-SM-Landscape" onClick={()=>{this.handleWebsiteClick(this.props.location.website)}} disabled={!this.isEnabled(this.props.location.website)} ><div className="button-icon"><i className="fas fa-sitemap"></i></div><br />Website</button>
              <button onClick={()=>{this.handleDirectionsClick(this.props.location.title, this.props.location.address)}} disabled={!this.isEnabled(this.props.location.address)} ><div className="button-icon"><i className="fas fa-map"></i></div><br />Map It</button>
              <button className="highlight-map-button" onClick={this.props.clickNext}><div className="button-icon"><i className="fas fa-arrow-right"></i></div><br />Next</button></div>
        </div>}
        
      </div>
      )
  }
}

MapWidgetHeader.propTypes = {
  location: PropTypes.object,
  clickNext: PropTypes.func,
  clickPrev: PropTypes.func,
  clickReview: PropTypes.func
};

export default MapWidgetHeader;


// */